<template>
  <div class=''>
    <product-dress-audit-store ref='store' @success='getTableData'/>
    <el-form class="radius_bg" inline :model='search'>
      <el-form-item>
        <el-input placeholder='商品名称' v-model='search.title' @change='handleQuery' clearable style='width: 200px;'/>
      </el-form-item>
      <el-form-item>
        <el-input placeholder='品牌' v-model='search.mini_brand_name' @change='handleQuery' clearable
                  style='width: 200px;'/>
      </el-form-item>
      <el-form-item>
        <el-select v-model='search.status' placeholder='审核状态' @change='handleQuery' clearable>
          <el-option v-for='item in OptionsStatus' :key='item.value' :label='item.label' :value='item.value'/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-if="hasAuthority('live-product-scope-all')" v-model='search.leader_id' filterable allow-create
                   placeholder='负责人(可自定义)' clearable @change='handleQuery'>
          <el-option :label='`${item.label} (${item.total})`' :value='item.value' v-for='(item, index) in OptionsLeader'
                     :key='index'/>
        </el-select>
        <span v-else> 负责人：{{ userInfo.nickname }} </span>
      </el-form-item>
      <el-form-item>
        <el-button type='primary' @click='handleQuery'>查询</el-button>
      </el-form-item>
    </el-form>
    <div class="default-table radius_bg" style="height: calc(100vh - 280px)">
      <el-table v-loading='loading' :data='table' style='width: 100%' height='calc(100% - 100px)'>
        <el-table-column align='center' label='序号' type='index' width='50' fixed='left'/>
        <el-table-column min-width='360' label='基本信息' fixed='left'>
          <template v-slot='{row:{cover_url,spec_name,title,created_at,mini_brand_name,},row}'>
            <div class='table-column__basic'>
              <el-image style='width: 76px; height: 76px' :src='cover_url' :preview-src-list='[cover_url]'/>
              <div class='table-column__basic-right'>
                <p class='title'>
                  <span>{{ mini_brand_name }}</span>
                  {{ title }}
                </p>
                <p class='time'>尺码颜色：<b>{{ spec_name }}</b></p>
                <p class='time'>季节性：<b>{{ row?.used_season }}</b></p>
                <p class='time'>申请时间：<b>{{ created_at|parseTime('{y}/{m}/{d} {h}:{i}') }}</b></p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop='samples' label='寄样明细' min-width='80' align='center'/>
        <el-table-column prop='remark' label='备注' min-width='60' align='center'/>
        <el-table-column min-width='130' label='预售补货'>
          <template v-slot='{row:{wares_prep}}'>
            <div v-if='typeof wares_prep==="string"'>
              <div v-for='(tmp,tmp_i) in wares_prep.split(" ； ")' :key='tmp_i'>
                {{ tmp || '' }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width='200' label='现货库存'>
          <template v-slot='{row:{wares_exist}}'>
            <div v-if='typeof wares_exist==="string"'>
              <div v-for='(tmp,tmp_i) in wares_exist.split(" ； ")' :key='tmp_i'>
                {{ tmp || '' }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop='delivery_cycle' label='发货时效' min-width='80' align='center' show-overflow-tooltip/>
        <el-table-column min-width='180' label='价格'>
          <template v-slot='{row:{price,lb_price,commission,support_situation}}'>
            <p>日常价：<b>{{ price || '-' }}</b></p>
            <p>直播价：<b>{{ lb_price || '-' }}</b></p>
            <p>佣金：<b>{{ commission || '-' }}</b></p>
            <p>保价情况：<b>{{ support_situation || '-' }}</b></p>
          </template>
        </el-table-column>
        <el-table-column prop='leader_name' label='负责人' min-width='80' align='center' show-overflow-tooltip/>
        <el-table-column min-width='100' label='审核状态' align='center' fixed='right'>
          <template v-slot='{row:{status,check_time,refuse_reason}}'>
            <el-tag v-if='status === 1'>待审核</el-tag>
            <el-tag type='success' v-else-if='status === 2'>审核通过</el-tag>
            <el-tag type='info' v-else>审核拒绝</el-tag>
            <div v-if='check_time'>{{ check_time|parseTime('{y}/{m}/{d}') }}</div>
            <el-tooltip v-if='status===3' effect='dark' :content="'拒绝原因：'+refuse_reason" placement='top-start'>
              <i class='el-icon-s-comment' style='cursor: pointer;'/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width='80' label='操作' align='center' fixed='right'>
          <template v-slot='{row:{status,id}}'>
            <el-button v-if='status===1' size='default' type='text' class='el-icon-s-check' @click='handleDo(id)'>审核
            </el-button>
            <el-button v-else size='default' type='text' class='el-icon-s-order' @click='handleDo(id)'>详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :background='true' :total='search.total' :page.sync='search.current_page'
                  :limit.sync='search.page_size' @pagination='getTableData'/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductDressAuditStore from './store.vue'

const { omitBy } = require('lodash')

const OptionsStatus = [
  { value: 1, label: '待审核' },
  { value: 2, label: '审核通过' },
  { value: 3, label: '审核拒绝' }
]
export default {
  name: 'ProductDressAudit',
  components: { ProductDressAuditStore },
  data() {
    return {
      OptionsStatus,
      OptionsLeader: [],
      loading: false,
      table: [],
      search: {
        c_type: 2,
        current_page: 1,
        page_size: 20,
        total: 0,
        title: null,
        status: null,
        leader_id: null,
        mini_brand_name: null
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getLeaders()
    this.getTableData()
  },
  methods: {
    handleDo: function(id) {
      this.$refs.store.show(id)
    },
    handleQuery: function() {
      this.search.current_page = 1
      this.getTableData()
    },
    getLeaders: async function() {
      this.OptionsLeader = await this.$api.productCheckLeaders()
    },
    getTableData: async function() {
      let params = { ...{}, ...this.search }
      params = omitBy(params, (value) => value === undefined || value === null || value === '')
      if (!this.hasAuthority('live-product-scope-all')) {
        params.leader_id = this.userInfo.id
      }

      this.loading = true
      const { list, page_info } = await this.$api.getProductAuditList(params)
      this.loading = false
      this.table = list || []
      this.search.current_page = page_info.current_page || 1
      this.search.page_size = page_info.page_size || 1
      this.search.total = page_info.total
    }
  }
}
</script>
<style lang='scss'>
@import "@/pages/lb/style/main.scss";

.productDressAudit_main-inner {
  height: calc(100vh - 90px);

  .page-content {
    min-height: unset !important;
    height: calc(100% - 38px);

    .productDressAudit_main-ape {
      height: 100%;

      .product-dress-audit-container {
        height: calc(100% - 62px);
        background: #fff;
        padding: 12px;
      }
    }
  }


  &__content {
    height: 100%;
    overflow: hidden;

    p {
      margin: unset;
    }
  }

  .table-column__basic {
    display: flex;
    justify-content: space-between;

    .el-image {
      width: 76px;
      border-radius: 6px;
    }

    &-right {
      width: calc(100% - 100px);

      .title {
        color: #45474d;
        font-weight: 700;
        line-height: 1.4;
        font-size: 13px;
        margin-bottom: 4px;
        margin-top: 2px;

        span {
          font-size: 12px;
          border: 1px solid #feb2c8;
          border-radius: 13px;
          margin-right: 6px;
          padding: 1px 6px;
        }
      }

      .time {
        font-size: 12px;
        color: #8a8e99;
      }
    }
  }
}
</style>
