<template>
  <div>
    <el-select v-bind='$attrs' v-on='$listeners' v-model='platform_code' :placeholder='placeholder'
               style='width: 100%' multiple @change='handleChange'
               clearable>
      <el-option v-for='(item,key) in platforms'
                 :label='item.name' :value='item.code'
                 :key='key' :disabled='!rangeAll&&selectRange.indexOf(item.code)===-1'>
        {{ item.name }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'PlatformSelect',
  props: {
    defaultCode: {
      type: [Array, String],
      return() {
        return []
      }
    },
    placeholder: {
      type: String,
      return() {
        return '请选择KOL平台'
      }
    },
    range: {
      type: Array,
      return() {
        return null
      }
    },
    purpose: {
      type: String,
      default() {
        return 'is_data_entry'
      }
    },
    rangeAll: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    range(val) {
      this.selectRange = val
    },
    defaultCode: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log(val)
        if (val)
          this.platform_code = [...val]
        else
          this.platform_code = []
      }
    },
    // platform_code: {
    //   handler(val) {
    //   }
    // }

  },
  computed: {},
  data() {
    return {
      platforms: [],
      platform_code: [],
      selectRange: []//可选的范围
    }
  },
  methods: {
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson && platformsJson.indexOf('{') > -1) {
        this.platforms = JSON.parse(platformsJson)
      }
      // 获取最新平台信息
      let searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getPlatformAll(searchCondition)
      // let {list} = await this.$api.getPlatforms()
      this.platforms = list

      // this.platform_code = [...this.defaultCode]
      // console.log('platforms', this.platforms)
      // this.$emit('loaded')
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      condition.status = 1

      if (this.purpose === 'is_data_entry') {
        condition.is_data_entry = 1
      }

      // if (this.purpose === 'is_plc_entry') {
      //   condition.is_plc_entry = 1
      // }
      return condition
    },
    handleChange(val) {
      if (val) {
        let info = []
        val.forEach((i) => {
          return this.platforms.find(value => {
            value.code === i ? info.push(value.name) : ''
          })
        })
        this.$emit('saved', { code: val, name: info || [] })
      }

      this.$emit('update:value', this.platform_code)
    }
  },
  async created() {
    await this.getPlatforms()
    // this.platform_code = [...this.defaultCode]
  }
}
</script>

<style scoped>

</style>
