<template>
  <el-button :type='type' :size='size' :icon='icon' :loading='loading' :disabled='disabled' :round="round" @click='handleClick'>
    <span v-html='text' />
  </el-button>
</template>

<script>

export default {
  name: 'MlButton',
  props: {
    text: {
      type: String,
      default: '提交'
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: String,
    icon: String,
    round: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleClick() {
      if (this.loading) {
        this.$notify({
          title: '警告',
          message: '操作频繁，请稍后重试。',
          type: 'warning'
        })
        return false
      }
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  }
}
</script>

<style scoped>
</style>
